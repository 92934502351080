import toArray from './toArray';

export const makeCopy = function makeCopy ( p, t ) {
    const c = t || {};
    for ( let i in p ) {
        if ( 'object' === typeof p[i] && p[i] !== null ) {
            c[i] = (p[i].constructor === Array) ? [] : {};
            makeCopy( p[i], c[i] );
        }
        else {
            c[i] = p[i];
        }
    }
    return c;
};

export function toQueryString( obj ) {
    let result = [];
    for ( let key in obj ) {
        if ( obj.hasOwnProperty( key ) ) {
            result.push( key + '=' + obj[key] );
        }
    }
    return result.join( '&' );
}

export function fromQueryString( queryString ) {
    queryString = String( queryString );
    if ( !queryString ) {
        return {};
    }

    queryString = queryString.split( '?' );

    if ( queryString.length > 1 ) {
        queryString = queryString.slice( 1 );
    }

    queryString = queryString.join( '?' );

    let object = {};
    let vars = queryString.split( '&' );

    if ( vars.length ) {
        for ( let i = 0, this_var; this_var = vars[i]; i++ ) {
            this_var = this_var.split( '=' );
            let key = decodeURIComponent( this_var[0] );
            let val = decodeURIComponent( this_var.slice( 1 ).join( '=' ).replace( /\+/g, ' ' ) );

            if ( object[key] !== undefined ) {
                if ( !object[key].push ) {
                    object[key] = [object[key]];
                }
                object[key].push( val );
            }
            else {
                object[key] = val;
            }
        }
    }
    return object;
}

export function hasKey( obj, k ) {
    return Object.prototype.hasOwnProperty.call( obj, k );
}

export function hasKeys( /* args */ ) {
    const KEYS = toArray( arguments ),
        cat = Array.prototype.concat;

    // check that obj has provided key
    const fn = ( obj ) => KEYS.every( k => hasKey( obj, k ) );

    fn.message = cat( ['Must have values for keys:'], KEYS ).join( ' ' );
    return fn;
}

/**
 * Performs validation on a configuration object
 * @params {Function} validator - function that return an error [] if object passed fails validation
 * @returns {Function} checker - pass object to validate return aggregated error list
 */
export function check( /* validators */ ) {
    const validators = toArray( arguments );

    return ( obj ) => {
        return validators.reduce( ( errs, check ) => {
            if ( !check( obj ) ) {
                // aggregate errors
                errs.push( check.message );
            }

            return errs;
        }, [] ); // => errs starts as empty array
    };
}

/**
 * higher-order validator function for creating validators to be passed to the checker function.
 * This method aids in attaching a message property to our functions to be used by the checker method
 *
 */
export function validator( message, fn ) {
    const f = ( /*args*/ ) => fn.apply( fn, arguments );

    f['message'] = message;
    return f;
}
