/**
 * Find nodes not nested inside a provided nested slector scope
 * @param selector
 * @param notInSelector
 * @returns {$.NodeList}
 */
$.fn.findNonNested = function ( selector, notInSelector ) {
    var origElement = $( this );
    return origElement.find( selector ).filter( function () {
        return origElement[0] == $( this ).closest( notInSelector )[0];
    } );
};

$.fn.accordion = function ( opt ) {
    var conf = $.extend( {
        TOGGLE_ITEM_SELECTOR: '> .accordion-content',
        MENU_LABEL_CLASS: 'accordion-label',
        MENU_ITEM_CLASS: 'accordion-item',
        ACTIVE_CLASS: 'accordion-item--active',
        TRIGGER_EVENT: 'click',
        transition_speed: 250,
        single_view: false,
        toggle_all_label: ['view all', 'hide all'],
        toggle_label: null
    }, opt );

    return $( this ).each( function () {
        $( 'html' ).addClass( 'js-accordion' );

        var selected_menu_item = null,
            $menu = $( this ),
            $menu_items = $menu.findNonNested( '.' + conf.MENU_ITEM_CLASS, '.accordion' ),
            $toggle_items = $menu_items.find( conf.TOGGLE_ITEM_SELECTOR ),
            $menu_item = null,
            all_items_open = false;

        // hide all items initialliy. There is an issue while trying to toggle items by .active class
        // when items are loaded dynamically and set display: none while init is running on accordion class
        // when close:accordion event is triggered it is unable to execute transtion since it's properties cannot
        // be read by $, instead just set to display: none by default.
        $toggle_items.hide();

        $menu.on( conf.TRIGGER_EVENT, '.' + conf.MENU_LABEL_CLASS, function ( e ) {
            e.stopPropagation();
            e.preventDefault();
            $menu_item = $( e.target ).parents( '.' + conf.MENU_ITEM_CLASS + ':first' );
            toggle_item( e.target );
        } );
        $( '.accordion-icon' ).on( 'click', function ( e ) {
            $menu_item = $( e.target ).parents( '.' + conf.MENU_ITEM_CLASS + ':first' );
            console.log( $menu_item );
            $menu.trigger( {type: 'open:accordion', item: $menu_item} );
        } );
        // instatiate accordion by toggling items based on presence of ACTIVE_CLASS
        $menu_items.each( function ( i, item ) {
            var $item = $( item ),
                event_type = ($item.hasClass( conf.ACTIVE_CLASS ))
                    ? 'open:accordion'
                    : 'close:accordion';

            $menu.trigger( {type: event_type, item: $item} );
        } );
        $menu.find( '.accordion-view-all' ).click( function () {
            var toggle_all_label = (toggle_all_items())
                ? conf.toggle_all_label[1]
                : conf.toggle_all_label[0];

            $( this ).html( toggle_all_label );
        } );

        // when this attribute present prevents subactions within accordion header from toggling accordion
        $menu.on( conf.TRIGGER_EVENT, '[data-accordion-subaction]', function ( e ) {
            e.stopImmediatePropagation();
        } );

        $menu.on( 'open:firstItem', function ( e ) {
            show_item( $menu_items.eq( 0 ), true );
        } );
        $menu.on( 'open:accordion', function ( e ) {
            show_item( e.item );
        } );
        $menu.on( 'close:accordion', function ( e ) {
            hide_item( e.item );
        } );
        $menu.on( 'open:accordion close:accordion', toggle_label );

        $menu.on( 'destroy:accordion', destroy );

        function destroy () {
            console.log( 'destroyed:', $menu );
            $menu.off();
        }

        function toggle_item ( item ) {
            if ( conf.single_view
                && item !== selected_menu_item
                && !$menu_item.hasClass( conf.ACTIVE_CLASS ) ) {
                hide_all();
                selected_menu_item = item;
            }

            (!$menu_item.hasClass( conf.ACTIVE_CLASS ))
                ? $menu.trigger( {type: 'open:accordion', item: $menu_item} )
                : $menu.trigger( {type: 'close:accordion', item: $menu_item} );
        };

        function show_item ( item, cancelScroll ) {
            item
                .addClass( conf.ACTIVE_CLASS )
                .find( conf.TOGGLE_ITEM_SELECTOR )
                .slideDown( conf.transition_speed, function () {
                    _triggerComplete( item, 'open', {cancelScroll: cancelScroll} );
                } )

        };

        function hide_item ( item ) {
            item
                .removeClass( conf.ACTIVE_CLASS )
                .find( conf.TOGGLE_ITEM_SELECTOR )
                .slideUp( conf.transition_speed, function () {
                    _triggerComplete( item, 'close' );
                } );
        };

        function toggle_label ( e ) {
            var label = conf.toggle_label,
                item = e.item;

            if ( label ) {
                item.find( '.' + conf.MENU_LABEL_CLASS ).html(
                    (item.hasClass( conf.ACTIVE_CLASS )) ? label[1] : label[0]
                );
            }
        };

        function hide_all () {
            $menu_items.each( function ( i, item ) {
                $menu.trigger( {type: 'close:accordion', item: $( item )} );
            } );
            return all_items_open = false;
        };

        function show_all () {
            $menu_items.each( function ( i, item ) {
                $menu.trigger( {type: 'open:accordion', item: $( item )} );
            } );
            return all_items_open = true;
        }

        function toggle_all_items () {
            return (all_items_open) ? hide_all() : show_all();
        };

        function _triggerComplete ( $item, type, opt ) {
            $menu.trigger( {
                item: $item,
                type: type + ':accordion-item'
            } );

            $( window ).trigger( 'window:height' );
            if ( 'open' === type ) {
                if ( !opt.cancelScroll ) {
                    $item.scrollToElement();
                }
                $( window ).trigger( 'waypoints:check' );
            }
        };

    } );
};
