import Corpus from './core';

module.exports = Corpus.modules.slider = (function () {

    var settings = {
        module_selector: '[data-slider]',
        control_class: 'slider-control',
        panel_class: 'slider-slide-content',
        slide_static_class: 'slider-slide',
        slide_reveal_selector: '[data-slide-reveal]'
    };

    return {
        name: 'slider',
        version: '0.0.1',
        // public initializer
        init: function ( scope, options ) {

            $( scope ).find( settings.module_selector ).each( function ( index, el ) {
                // create a scoped instance for initialization
                var scoped_module = Object.create( slider );
                // merge options with a copy of default settings so scoped modules can be configured independently
                scoped_module.init( el, $.extend( true, Object.makeCopy( settings ), options ) );

            }.bind( this ) );

            return this;
        }
    };

}());

var slider = {
    init: function ( el, opt ) {
        $( 'html' ).addClass( 'js-slider' );

        this.$el = $( el );
        this.settings = opt;
        this._init_events();

        this.reset();
    },

    _init_events: function () {
        var self = this,
            $el = this.$el;

        $( window ).on( 'resize', this.reset.bind( this ) );

        if ( Modernizr.touchevents ) {
            $el.on( 'touchstart', function ( e ) {
                var x1, y1, x2, y2, deltaX, deltaY,
                    scrolling_threshold = 20,
                    swipe_threshold = 30,
                    last_x,
                    touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];

                x1 = last_x = touch.clientX;
                y1 = touch.clientY;

                $el.on( 'touchmove', function ( e ) {
                    var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];

                    x2 = touch.clientX;
                    y2 = touch.clientY;
                    deltaX = Math.abs( x2 - x1 );
                    deltaY = Math.abs( y2 - y1 );

                    // if threshold exceeds last x pos than discern this as a swipe and
                    // allow event to fall through, otherwise stop it
                    if ( swipe_threshold > Math.abs( last_x - x2 ) ) {
                        e.stopPropagation();
                    }

                    // ensure there is enough movement to determine users intentions
                    if ( scrolling_threshold < deltaX + deltaY ) {
                        // check direction of movement to determine if user is scrolling
                        if ( deltaY > deltaX ) {
                            return;
                        }

                        // cancel default ONLY if not scrolling
                        e.preventDefault();
                        self._move_slider( touch.pageX - element_position( $el[0] ).x );
                    }

                    last_x = x2;
                } );
            } );

            $( document ).on( 'touchend', function () {
                $el.off( 'touchmove' );
            } );
        }
        $el.on( 'mousedown', function ( e ) {
            // prevent text selection
            e.preventDefault();

            $el.on( 'mousemove', function ( e ) {
                e.preventDefault();
                self._move_slider( e.pageX - element_position( $el[0] ).x );
            } );
        } );

        $( document ).on( 'mouseup', function () {
            $el.off( 'mousemove' );
        } );
    },

    reset: function () {
        // start in the middle
        this._move_slider( this.$el.width() / 2 );
    },

    _move_slider: function ( pos ) {
        var $control = this.$el.find( '.' + this.settings.control_class ),
            $reveal = this.$el.find( this.settings.slide_reveal_selector );

        pos = this._set_within_range( pos );
        $control.css( 'left', this._position_to_percent( pos ) + '%' );
        $reveal.css( 'clip', 'rect(0px ' + this.$el.width() + 'px ' + this.$el.height() + 'px ' + pos + 'px)' );
    },

    _set_within_range: function ( pos ) {
        var max = this.$el.width();
        return pos < 0 ? 0 : pos > max ? max : pos;
    },

    _position_to_percent: function ( pos ) {
        return (pos / this.$el.width()) * 100;
    }

};

function element_position ( e ) {
    var $el = $( e ), x = 0, y = 0, borderTop, borderLeft, paddingTop, paddingLeft;
    var inner = true;
    do {
        x += e.offsetLeft;
        y += e.offsetTop;

        y += parseInt( $el.css( 'borderTop' ), 10 ) || 0;
        x += parseInt( $el.css( 'borderLeft' ), 10 ) || 0;

        if ( inner ) {
            y += parseInt( $el.css( 'paddingTop' ), 10 );
            x += parseInt( $el.css( 'paddingLeft' ), 10 );
        }
        inner = false;
    }
    while ( e = e.offsetParent );
    return {x: x, y: y};
}
