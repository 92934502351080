$.fn.toggleQtySelect = function () {
    // create a master <select> for cloning
    var $customSelect,
        options = [1, 2, 3, 4, 5, 6, 7, 8, 9],
        $select = $( '<select tabindex="0" />' );

    // append "zero" option if default value
    if ( '0' === this.val() || this.attr( 'data-allow-zero' ) !== undefined ) {
        options.unshift( 0 );
    }

    $.map( options, function ( val, i ) {
        var opt = $( '<option value="' + val + '">' + val + '</option>' );
        $select.append( opt );
    } );
    $select.append( $( '<option value="10">10+</option>' ) );
    $customSelect = $select.toCustomField().addClass( 'qty' );

    function restoreValueIfEmpty ( val ) {
        return function () {
            if ( typeof this.value == 'undefined' || this.value == '' ) {
                $( this ).val( val ).trigger( 'change' );
            }
        }
    }

    // factory for cloning customSElect fields
    function selectFactory ( $customSelect, $input ) {
        var $qtySelect = $customSelect.clone( true );

        $input.on( 'change', function () {
            if ( $qtySelect.val() !== $input.val() ) {
                $qtySelect.val( $input.val() ).trigger( 'change' );
            }
        } );

        $input.on( 'keypress', function ( e ) {
            if ( e.which === 13 ) {
                $input.blur();
            }
        } );

        $qtySelect.on( 'focusin', function () {
            $qtySelect.addClass( 'focus' );
        } );
        $qtySelect.on( 'focusout', function () {
            $qtySelect.removeClass( 'focus' );
        } );

        $qtySelect.on( 'change', 'select', function () {
            if ( '10' === this.value ) {
                $input.one( 'focusout', restoreValueIfEmpty( $input.val() ) )
                    .val( '' )
                    .show()
                    .focus();

                $qtySelect.hide();
            }
            else {
                $input.val( this.value ).trigger( 'change' );
            }
        } );

        return $qtySelect;
    };

    function digest ( $input, $qtySelect ) {
        // if current value NaN, set to "1" in options lists
        var currentVal = parseInt( $input.val(), 10 );

        // if no value, set target to <select>'s default
        // else set <select> to target's current value
        if ( isNaN( currentVal ) || typeof currentVal == 'undefined' || currentVal === '' ) {
            //$input.val( $qtySelect.find( 'select' ).val() );
            $input.val( 1 );
            $qtySelect.find( 'option' ).filter( function () {
                    return $( this ).text() == '1';
                }
            ).prop( 'selected', true ).trigger( 'change' );
        }
        else {
            $qtySelect.find( 'option' ).filter( function () {
                    return $( this ).text() == currentVal.toString();
                }
            ).prop( 'selected', true ).trigger( 'change' );
        }
    };

    var digestCollection = [];
    this.on( 'digest', function () {
        for ( var i = 0, l = digestCollection.length; i < l; i++ ) {
            digestCollection[i]();
        }
    } );

    return this.each( function () {
        var $input = $( this ),
            currentVal = parseInt( $input.val(), 10 ),
            $qtySelect;

        $input.forceNumericRange( {min: 0, max: 999} );

        if ( currentVal >= 10 ) {
            return;
        }

        $qtySelect = selectFactory( $customSelect, $input );
        digestCollection.push( digest.curry( $input, $qtySelect ) );
        digest( $input, $qtySelect );

        $input.hide();
        this.parentNode.appendChild( $qtySelect[0] );
    } );
};
