import Corpus from './core';
import assign from 'object-assign';
import {makeCopy} from '@util/ObjectUtils';

var breakpoint_size;
var carousel = {

    init: function ( el, options ) {
        this.el = $( el );

        // get inline options set from data-options on DOM node
        this.settings = assign( options, Corpus.parse_options( this.el.data( 'options' ) ) );
        // dots nav requires slide_len which depends on node_init
        if ( this.settings.navigation_style === 'dots' ) {
            this
                .el
                .append( this._nav_dots_html() );
        }
        else {
            this
                .el
                .append( this._nav_prev_next_html() );
        }

        this.init_nodes( {
            '$carousel_container': '.' + this.settings.container_class,
            '$carousel_list': '.' + this.settings.list_class,
            '$carousel_nav': '.' + this.settings.nav_class,
            '$prev': '.' + this.settings.prev_class,
            '$next': '.' + this.settings.next_class,
            '$li': '.' + this.settings.list_class + '> li'
        } );

        breakpoint_size = get_breakpoint();
        this.current = 0;
        this.multiplier = this.settings.multiplier;
        this.slide_len = this.get_slide_length();
        this.num_pages = this.slide_len / this.multiplier;
        this.container_margin_left = this.get_slide_position();
        this.anim_limit = 0;

        this.build_carousel();
        this.init_events();

        this.uncloak();

        this.goto( 0 );

        return this;
    },

    init_events: function () {
        var self = this;

        this
            .el
            .on( 'corpus.carousel.change', this._update_nav.bind( this ) );

        this
            .nodes
            .$carousel_nav
            .on( 'click', '[data-action]', function ( e ) {
                e.preventDefault();
                var action = e
                    .currentTarget
                    .getAttribute( 'data-action' );
                switch ( action ) {
                    case 'next':
                        self.next();
                        break;
                    case 'prev':
                        self.prev();
                        break;
                    default:
                        self.goto( parseInt( action, 10 ) );
                        break;
                }
            } );

        // listen for changes when carousel is paired with a tab-panel or tab-control and change slide accordingly
        this
            .nodes
            .$carousel_list
            .on( 'corpus.tab.change.panel corpus.tab.change.control', function ( e ) {
                this.goto( Math.floor( e.index / this.multiplier ) );
            }.bind( this ) );

        // enable swiping
        if ( Modernizr.touchevents ) {
            var $container = this.nodes.$carousel_container;

            $container.on( 'touchstart', function ( e ) {
                var touch = e.originalEvent.targetTouches[0],
                    threshold = 80,
                    x1 = touch.pageX,
                    y1 = touch.pageY,
                    x2 = 0,
                    y2 = 0,
                    deltaX = 0,
                    deltaY = 0,
                    left_pos,
                    isScrolling = null;

                //Touch Move
                $container.on( 'touchmove', function ( e ) {
                    var touch = e.originalEvent.touches[0];
                    x2 = touch.pageX;
                    y2 = touch.pageY;
                    deltaX = x1 - x2;
                    deltaY = y1 - y2;
                    left_pos = this.container_margin_left - (this._to_percent( deltaX ));

                    // is scrolling - ignore swipe
                    if ( !!isScrolling || Math.abs( deltaY ) > Math.abs( deltaX ) ) {
                        isScrolling = true;
                        return;
                    }

                    e.preventDefault();
                    // todo: positioning needs work
                    this
                        .nodes
                        .$carousel_list
                        .css( {
                            marginLeft: left_pos + '%'
                        } );
                }.bind( this ) );

                //Touch Move
                $container.on( 'touchend', function ( e ) {
                    var exceeds_threshold = Math.abs( deltaX ) > threshold;
                    //                            console.log( x1, x2, deltaX, exceeds_threshold, threshold );
                    //                            console.log( 'next:', deltaX > 0 && exceeds_threshold , 'prev:', deltaX < 0 && exceeds_threshold );

                    if ( deltaX > 0 && exceeds_threshold ) {
                        this.next();
                    }
                    else if ( deltaX < 0 && exceeds_threshold ) {
                        this.prev();
                    }
                    else {
                        this.pos_carousel();
                    }

                    // reset touch points
                    x1 = x2 = deltaX = y1 = y2 = deltaY = 0;

                }.bind( this ) );

            }.bind( this ) );

            $( document ).on( 'touchend', function () {
                $container.off( 'touchmove touchend' );
            } );
        }
    },

    init_nodes: function ( nodes ) {
        this.nodes = this.nodes || {};

        var node;

        for ( node in nodes ) {
            if ( nodes.hasOwnProperty( node ) ) {
                this.nodes[node] = this
                    .el
                    .find( nodes[node] );
            }
        }
    },

    _nav_prev_next_html: function () {
        var style = this.settings.control_button_style;
        return '<nav class="carousel-nav ' +
            this.settings.navigation_style +
            '">' +
            '<a href="#" class="prev disabled" data-action="prev"><i class="icon' +
            (style
                ? '-' + style
                : '') +
            '-arrow-left"></i> <span class="carousel-nav-label">' +
            this.settings.previous_label +
            '</span></a>' +
            '<a href="#" class="next disabled" data-action="next"><span class="carousel-nav-label">' +
            this.settings.next_label +
            '</span> <i class="icon' +
            (style
                ? '-' + style
                : '') +
            '-arrow-right"></i></a>' +
            '</nav>';
    },

    _nav_dots_html: function () {
        var html = '<ul class="carousel-nav dots">';
        for ( var i = 0, l = this.get_slide_length(); i < l; i++ ) {
            var label = parseInt( i, 10 ) + 1;
            html += '<li data-action="' + i + '"><span class="carousel-nav-label">' + label + '</span></li>';
        }
        html += '</ul>';
        return html;
    },

    _slide_number_html: function ( slide_number, total_slides ) {
        var self = this,
            $container = $( '<div class="' + self.settings.slide_number_class + '"></div>' );
        $container.append( '<span>' + slide_number + '</span> of <span>' + total_slides + '</span>' );
        return $container;
    },

    _update_nav: function ( e ) {
        if ( this.settings.navigation_style == 'dots' ) {
            var index = this.current,
                nav_items = this
                    .nodes
                    .$carousel_nav
                    .find( '> li' );
            nav_items.each( function ( i, item ) {
                $( this ).toggleClass( 'selected', index === i );
            } );
        }
        else {
            this
                .nodes
                .$next
                .toggleClass( 'disabled', this.anim_limit <= this.current );
            this
                .nodes
                .$prev
                .toggleClass( 'disabled', 0 >= this.current );
        }
    },

    destroy: function () {
        this
            .nodes
            .$carousel_nav
            .off();
        this
            .nodes
            .$carousel_container
            .off();
        this
            .nodes
            .$carousel_list
            .off();
        this
            .el
            .off();

        this
            .nodes
            .$carousel_nav
            .remove();
    },

    get_slide_length: function () {
        // if slide_len not set yet, get count from existing DOM
        return this.slide_len || this
            .el
            .find( '.' + this.settings.list_class + ' > li' )
            .length;
    },

    build_carousel: function () { //Build the Carousel
        this.current = 0;

        var nav_position_class = (this.settings.navigation_placement === 'top')
            ? 'carousel-nav-position-top'
            : 'carousel-nav-position-overlay';
        this
            .el
            .addClass( nav_position_class );
        // add slide counter
        // todo: need flag to determine if this should show, is confusing when used with multipliers > 1
        //this.el.append( this._slide_number_html( this.current +1, this.slide_len  ) );

        this.size_carousel();
        // todo: handle updates for dot nav
        this._update_nav();
    },

    uncloak: function () {
        this
            .el
            .removeClass( 'carousel-cloak' );
    },

    size_carousel: function () {
        //Determine the size and number of panels to reveal
        this.anim_limit = Math.ceil( this.slide_len / this.multiplier ) - 1;
        // calculate carousle width based on slideCount/inViewCount
        const newWidth = ((100 * this.slide_len) / this.multiplier);
        this.nodes.$carousel_list.css( {width: newWidth + '%'} );
        this.nodes.$li.outerWidth( (100 / this.slide_len) + '%' );
    },

    get_slide_position: function () {
        // convert current marginLeft to % for swipe start pos
        var left_pos = this.nodes.$carousel_list.css( 'marginLeft' ),
            width = this.nodes.$li.width(),
            multiplier = this.multiplier;

        return Math.round( parseInt( left_pos ) / (width * multiplier) ) * 100;
    },

    pos_carousel: function () { //Animate Carousel. CSS transitions used for the actual animation.
        var pos = -this.current * 100,
            self = this;

        this
            .nodes
            .$carousel_list
            .addClass( 'animating' )
            .css( {
                marginLeft: pos + '%'
            } );

        setTimeout( function () {
            self
                .nodes
                .$carousel_list
                .removeClass( 'animating' );
            self.container_margin_left = self.get_slide_position();
        }, 500 ); // will work with every browser
    },

    prev: function () {
        if ( this.current > 0 ) {
            this._set_current( this.current - 1 );
        }
        this.pos_carousel();
    },

    next: function () {
        if ( this.current < this.anim_limit ) {
            this._set_current( this.current + 1 );
        }
        this.pos_carousel();
    },

    goto: function ( idx ) {
        this._set_current( this._set_within_range( idx ) );
        this.pos_carousel();
    },

    _set_current: function ( val ) {
        this.current = val;
        this
            .nodes
            .$carousel_list
            .trigger( {type: 'corpus.carousel.change', index: this.current} );
    },

    _set_within_range: function ( idx ) {
        var max = this.anim_limit;
        return idx < 0
            ? 0
            : idx > max
                ? max
                : idx;
    },

    _to_percent: function ( diff ) {
        return ((parseInt( diff ) / $( document ).width()) * 100);
    }

};

// helpers
function get_breakpoint () {
    /* Conditional CSS http://adactio.com/journal/5429/ */
    if ( window.getComputedStyle ) {
        return window
            .getComputedStyle( document.body, ':after' )
            .getPropertyValue( 'content' );
    }

    // default to large if getComputedStyle not available
    return 'large';
}

module.exports = Corpus.modules.carousel = (function () {

    var settings = {
        module_selector: '.carousel',
        multiplier: 1,
        container_class: 'carousel-list-container',
        list_class: 'carousel-list',
        next_label: 'Next',
        previous_label: 'Prev',
        next_class: 'next',
        prev_class: 'prev',
        nav_class: 'carousel-nav',
        control_button_style: 'chevron',
        navigation_style: '',
        active_slide_class: 'active',
        slide_number_class: 'carousel-slide-number',
        transition_class: 'animating',
        navigation_placement: 'overlay'
    };

    return {
        name: 'carousel', version: '0.0.1',

        // public initializer
        init: function ( scope, options ) {
            //                    console.log( 'initialized %s', this.name, arguments );
            $( 'html' ).addClass( 'js-carousel' );

            $( scope )
                .find( settings.module_selector )
                .each( function ( index, el ) {
                    // create a scoped instance for initialization
                    var scoped_module = this.scoped_module = Object.create( carousel );
                    // merge options with a copy of default settings, then global-settings so scoped modules can
                    // be configured independently
                    scoped_module.init( el,
                        $.extend( true, makeCopy( settings ), Corpus.getGlobalOptions( this.name ), options ) );

                }.bind( this ) );

            return this;
        },

        off: function () {
            this.scoped_module && this
                .scoped_module
                .destroy();
        }
    };

}());

//export default Corpus.modules.carousel;
