import Modernizr from '@vendor/modernizr/modernizr-custom.exec.js';

import 'svgxuse';
import 'lazysizes';

import '@modules/jquery-import';

// --> npm imports
import '@plugins/jquery.easing';
import 'waypoints/lib/jquery.waypoints';
import 'jquery-menu-aim';

// NOTE --> custom plugins from@plugins dir
// import '@plugins/jquery.findnonnested';
// todo -> determine which of these plugins could be lazy loaded
import '@plugins/jquery.validate';
import '@plugins/jquery.accordion';
// import '@plugins/jquery.contenttoggle';
import '@plugins/jquery.customfields';
// import '@plugins/jquery.dropdownmenu';
// import '@plugins/jquery.color';
// import '@plugins/jquery.forceNumericRange';
// import '@plugins/jquery.giggle';
// import '@plugins/jquery.highlight';
// import '@plugins/jquery.injectformlabels';
// import '@plugins/jquery.isolatedScroll';
// import '@plugins/jquery.makesticky';
// import '@plugins/jquery.offsetFromAncestor';
// import '@plugins/jquery.placeholder';
// import '@plugins/jquery.slidingmenu';
// import '@plugins/jquery.shortstring';
import '@plugins/jquery.showHint';
// import '@plugins/jquery.smartresize';
// import '@plugins/jquery.sortelements';
// import '@plugins/jquery.tabs';
// import '@plugins/jquery.togglable';
// import '@plugins/jquery.toggleradioselect';
import '@plugins/jquery.toggleqtyselect';
// import '@plugins/jquery.togglemenu';
// import '@plugins/jquery.upidialog';
import '@plugins/jquery.whenInView';

// --> Corpus plugin imports
import Corpus from '@plugins/corpus/core';
import Slider from '@plugins/corpus/slider';
import carousel from '@plugins/corpus/carousel';
// import tabs from '@plugins/corpus/tabs';
