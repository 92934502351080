$.fn.whenInView = function ( fn, opt ) {

    var fn = fn || function () {},
        waypoints = this,
        _timeoutId = null,
        defaults = {
            detach: true,
            offset: 100,
            context: window,
            scrollThrottle: 30,
            resizeThrottle: 100,
            viewKey: 'inview-id',
            inviewEvent: 'inview.waypoint'
        };

    opt = $.extend( defaults, opt );

    let $window = $( opt.context );
    var _handler = function ( e ) {
        var timeout = (e && e.type === 'resize') ? opt.resizeThrottle : opt.scrollThrottle;
        if ( _timeoutId ) {
            //                console.log( 'clear:', _timeoutId );
            clearTimeout( _timeoutId );
            _timeoutId = null;
        }

        _timeoutId = setTimeout( _checkWaypoints, timeout );
    };

    var _checkWaypoints = function () {
        //            console.log( 'waypoints.length:', waypoints.length );
        if ( !waypoints.length ) {
            _detachWaypoints();
        }

        waypoints.each( function ( i, el ) {
            //                console.log( 'check waypoint for:', el );
            var $el = $( el ),
                posTop,
                inView;

            if ( $el.data( 'is-inview' ) ) {
                return;
            }

            posTop = el.getBoundingClientRect().top;
            inView = 0 >= (posTop - (opt.offset + $window.height()));

            //                console.log( 'inView', inView, posTop, $el );

            if ( inView ) {
                if ( !$el.is( ':visible' ) ) {
                    return;
                }

                $el.data( 'is-inview', true ).trigger( opt.inviewEvent );
                waypoints = _filterWaypoints( el );
                fn && fn.call( el );
            }
        } );
    };

    var _filterWaypoints = function ( el ) {
        return waypoints.not( el );
    };

    var _attachWaypoints = function () {
        $window.on( 'scroll resize waypoints:check', _handler );
    };
    var _detachWaypoints = function () {
        $window.off( 'scroll resize waypoints:check', _handler );
    };

    var _creatId = function () {
        return Math.guid()
    };
    var initialize = function () {
        // attach an id to each waypooint
        this.each( function () {
            $( this ).data( opt.inviewKey, _creatId() );
        } );

        _attachWaypoints();
        // make initiali call in event that item is already inview
        _handler();
    };

    initialize.call( this );
};
